import "./styles.css";
import axios from "axios";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchGamesInfo,
  getSearchGamesInfoPriceTr,
} from "../../../../features/SearchSlice/SearchSlice";
import { getCarrencyCurSu } from "../../../../features/CarrencyCurSuSlice/CarrencyCurSuSlice";
import { BarLoader } from "react-spinners";
const DonateItem = ({ id }) => {
  const carrencyArCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyArCurSu
  );
  const carrencyTrCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyTrCurSu
  );
  const carrencyRuCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyRuCurSu
  );

  // const [priceAr, setPriceAr] = useState(0); // цена в рублях в аргентине
  // const [priceTr, setPriceTr] = useState(0); // цена в рублях в турции

  const [nameDonate, setNameDonate] = useState("");
  const [donateImg, setDonateImg] = useState("");
  // const [donatePriceAr, setDonatePriceAr] = useState(0); // Цена в АРС
  // const [donatePriceTr, setDonatePriceTr] = useState(0); // Цена в Лирах
  const MARKUP = 400;
  //   console.log(id);

  const getDonateInfo = async () => {
    const responseAr = await axios.get(
      `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=ar&languages=ru-ru`
    );

    const responseTr = await axios.get(
      `https://displaycatalog.mp.microsoft.com/v7.0/products?bigIds=${id}&market=tr&languages=ru-ru`
    );

    setNameDonate(
      responseAr.data.Products[0].DisplaySkuAvailabilities[0].Sku
        .LocalizedProperties[0].SkuTitle
    );
    setDonateImg(
      responseAr.data.Products[0].LocalizedProperties[0].Images[0].Uri
    );

    const donateInfo = {
      nameDoname:
        responseAr.data.Products[0].DisplaySkuAvailabilities[0].Sku
          .LocalizedProperties[0].SkuTitle,
      donateImg:
        responseAr.data.Products[0].LocalizedProperties[0].Images[0].Uri,
      donatePriceAr:
        responseAr.data.Products[0].DisplaySkuAvailabilities[0]
          .Availabilities[0].OrderManagementData.Price.ListPrice,
      donatePriceTr:
        responseTr.data.Products[0].DisplaySkuAvailabilities[0]
          .Availabilities[0].OrderManagementData.Price.ListPrice,
    };

    return donateInfo;
  };

  const [isSwitchCalcDt, setIsSwitchCalcDt] = useState(false);

  const [priceArDt, setPriceArDt] = useState(0); // цена в рублях в аргентине c накруткой DreamTeam
  const [priceTrDt, setPriceTrDt] = useState(0); // цена в рублях в турции c накруткой DreamTeam

  const priceCalculationDt = () => {
    const price = getDonateInfo()
      .then((res) => {
        const ar = res.donatePriceAr / (carrencyArCurSu / carrencyRuCurSu);
        const tr = res.donatePriceTr / (carrencyTrCurSu / carrencyRuCurSu);
        const markup = 400;

        if (ar !== 0) {
          // if (ar < 1670) {
          //   setPriceArDt(ar + ar * 0.15 + 200 + markup);
          // } else if (ar < 2920) {
          //   const sum = ar + ar * 0.15;
          //   setPriceArDt(ar + ar * 0.15 + sum * 0.12 + markup);
          // } else if (ar > 2920) {
          //   const sum = ar + ar * 0.15;
          //   setPriceArDt(ar + ar * 0.15 + 350 + markup);
          // }
          setPriceArDt(
            ar * 1.78 + 200 + markup + (ar * 1.78 + 200 + markup) * 0.13
          );
        } else setPriceArDt(999999);

        if (tr !== 0) {
          // if (tr < 1670) {
          //   setPriceTrDt(tr + tr * 0.15 + 200 + markup);
          // } else if (tr < 2920) {
          //   const sum = tr + tr * 0.15;
          //   setPriceTrDt(tr + tr * 0.15 + sum * 0.12 + markup);
          // } else if (tr > 2920) {
          //   const sum = tr + tr * 0.15;
          //   setPriceTrDt(tr + tr * 0.15 + 350 + markup);
          // }
          setPriceTrDt(
            tr + tr * 0.1 + 200 + markup + (tr + tr * 0.1 + 200 + markup) * 0.13
          );
        } else setPriceTrDt(999999);
      })
      .then(() => {
        setIsSwitchCalcDt(true);
      });
  };

  useEffect(() => {
    // getDonateInfo();
    // priceCalculationDt();
  }, []);

  useEffect(() => {
    priceCalculationDt();
  }, [isSwitchCalcDt]);

  return (
    <>
      {isSwitchCalcDt != true ? (
        <div className="loader">
          <BarLoader color="#285f8f" />
        </div>
      ) : (
        <div className="donate-item">
          <div className="donate-item__info">
            {/* <img style={{ width: 50 }} src={donateImg} /> */}
            <img
              style={{ width: 50 }}
              src={`https:${donateImg}?w=180&h=300&q=50&mode=scale`}
            />

            {/* src={`https:${item.logoPicture}?w=162&h=300&q=90&mode=scale`} */}
            <div style={{ fontSize: 15 }}>{nameDonate}</div>
          </div>

          {priceArDt === 0 || isNaN(priceArDt) ? (
            <BarLoader color="#285f8f" />
          ) : (
            <div className="donate-price">
              {" "}
              {priceArDt < priceTrDt ? (
                <span className="donate">
                  {Math.ceil(priceArDt / 10) * 10}{" "}
                  <span style={{ fontSize: 5 }}>I</span> рублей{" "}
                </span>
              ) : (
                <span className="donate">
                  {Math.ceil(priceTrDt / 10) * 10}{" "}
                  <span style={{ fontSize: 5 }}>II</span> рублей{" "}
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DonateItem;
